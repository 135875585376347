import React from "react";
import PropTypes from "prop-types";

const Button = ({ onClick, className }) => {
  return (
    <button
      className={className}
      // variant="outline-primary"
      type="button"
      aria-label="Login"
      onClick={onClick}
    >
      Login
    </button>
  );
};
Button.propTypes = {
  onClick: PropTypes.func,
  className: PropTypes.string,
};

Button.defaultProps = {
  onClick: null,
  className: PropTypes.string,
};
export default Button;
