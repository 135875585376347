import React, { useState } from "react";
import { Link, navigate } from "gatsby";
import PropTypes from "prop-types";
import { useIdentityContext } from "react-netlify-identity-widget";
import clsx from "clsx";
import { Navbar, Container, Nav } from "react-bootstrap";
import useWindowOnScroll from "hooks/useWindowOnScroll";
import useSmoothScrollTo from "hooks/useSmoothScrollTo";
import Icon from "components/Icon";
import NavItem from "components/NavItem";
import Login from "components/Login";

import Logo from "components/Logo";

import "./Navbar.scss";

const MyNavbar = ({ anchors, frontmatter, isPage }) => {
  const { brand, menuText } = frontmatter;
  const { user, isLoggedIn, logoutUser } = useIdentityContext();

  const handleScrollToTop = useSmoothScrollTo(0);

  const [expanded, setExpanded] = React.useState(false);

  const toggleMenu = React.useCallback(() => {
    setExpanded(!expanded);
  }, [expanded]);

  const closeMenu = React.useCallback(() => {
    setExpanded(false);
  }, []);

  const handleBrandClick = React.useCallback(() => {
    closeMenu();
    handleScrollToTop();
  }, [closeMenu, handleScrollToTop]);

  const [shrink, setShrink] = React.useState(false);
  const handleWindowScroll = React.useCallback(() => {
    const scrollTop = document.documentElement.scrollTop || document.body.scrollTop;
    setShrink(scrollTop > 100);
  }, []);
  useWindowOnScroll(handleWindowScroll);

  return (
    <Navbar
      className={clsx("navbar-root", { "navbar-shrink": shrink }, expanded ? "isOpen" : "")}
      expand="lg"
      fixed="top"
      expanded={expanded}
    >
      <Container>
        {isPage === "yes" ? (
          <Navbar.Brand className="cursor-pointer" href="/">
            <Logo alt={brand} className="logo" />
          </Navbar.Brand>
        ) : (
          <Navbar.Brand className="cursor-pointer" onClick={handleBrandClick}>
            <Logo alt={brand} className="logo" />
          </Navbar.Brand>
        )}

        <Navbar.Toggle onClick={toggleMenu} aria-label="Toggle navigation">
          {menuText}
          <Icon iconName="BarsIcon" />
        </Navbar.Toggle>
        <Navbar.Collapse>
          {isPage === "yes" ? (
            ""
          ) : (
            <Nav className="text-uppercase ml-auto">
              {anchors.map((anchor) => (
                <NavItem key={anchor} to={anchor} onClick={closeMenu} />
              ))}
              {/* <Nav.Link href="/app/login">Login</Nav.Link> */}
            </Nav>
          )}

          {/* <Login /> */}

          {isLoggedIn ? (
            <Link to="/app" className="btn btn-secondary btn-md">
              Account
            </Link>
          ) : (
            <Link to="/app/login" className="btn btn-secondary btn-md">
              Login
            </Link>
          )}
        </Navbar.Collapse>
      </Container>
    </Navbar>
  );
};

MyNavbar.propTypes = {
  anchors: PropTypes.arrayOf(PropTypes.string),
  frontmatter: PropTypes.object,
  isPage: PropTypes.string,
  // extraItems: PropTypes.any,
};

MyNavbar.defaultProps = {
  anchors: [],
  frontmatter: {},
  isPage: "",
  // extraItems: null,
};

export default MyNavbar;
