import React, { useState } from "react";
import PropTypes from "prop-types";
import { Form, Button } from "react-bootstrap";

const ContactForm = ({ className }) => {
  const [validated, setValidated] = useState(false);

  const handleSubmit = (event) => {
    const form = event.currentTarget;
    if (form.checkValidity() === false) {
      event.preventDefault();
      event.stopPropagation();
    }

    setValidated(true);
  };

  return (
    <Form
      name="contact"
      method="post"
      data-netlify="true"
      data-netlify-honeypot="bot-field"
      validated={validated}
      noValidate
      onSubmit={handleSubmit}
      className={className}
    >
      <input type="hidden" name="form-name" value="contact" />
      <p hidden>
        <label htmlFor="honeypot">
          Don’t fill this out if you’re human: <input id="honeypot" name="bot-field" />
        </label>
      </p>

      <Form.Group controlId="contactFormName">
        <Form.Label>Full name</Form.Label>
        <Form.Control type="name" name="name" required />
      </Form.Group>
      <Form.Group controlId="contactFormEmail">
        <Form.Label>Email</Form.Label>
        <Form.Control type="email" name="email" required />
      </Form.Group>
      <Form.Group controlId="contactFormPhone">
        <Form.Label>Phone number</Form.Label>
        <Form.Control type="phone" name="phone" required />
      </Form.Group>
      <Form.Group controlId="contactFormInterest">
        <Form.Label>Interest</Form.Label>
        <Form.Control as="select" name="interest" custom required>
          <option>Investor</option>
          <option>Partner</option>
          <option>Career</option>
          <option>Other</option>
        </Form.Control>
      </Form.Group>
      <Form.Group controlId="contactFormMessage">
        <Form.Label>Message</Form.Label>
        <Form.Control as="textarea" rows={3} name="message" required />
      </Form.Group>
      <Button variant="primary" type="submit">
        Submit
      </Button>
    </Form>
  );
};

ContactForm.propTypes = {
  className: PropTypes.string,
};
ContactForm.defaultProps = {
  className: "",
};

export default ContactForm;
